import React , { useState, useEffect } from 'react';
import { Route, Switch, withRouter, NavLink, useHistory, useRouteMatch } from 'react-router-dom'
import axios from "axios"; 
import Toggle from 'react-toggle';
import { Modal } from 'react-responsive-modal';
import EmbedForm from '../../Apps/Embeds/EmbedForm.js';

const ClientPortal = ({organization, refetchData}) => {
  const history = useHistory();
  const match = useRouteMatch();
  const [loaded, setLoaded] = useState(false)
  const [disabled, setDisabled] = useState(false);
  const [open, setOpen] = useState(false);
  const [disableBrandAssets, setDisableBrandAssets] = useState(!organization.disable_brand_assets);
  const [disableReferrals, setDisableReferrals] = useState(!organization.disable_referrals);
  const [disableServices, setDisableServices] = useState(!organization.disable_services);
  const [disableInvoices, setDisableInvoices] = useState(!organization.disable_invoices);
  const [embeds, setEmbeds] = useState([]);

  useEffect(() => {
    fetchEmbeds();
  }, []);

  const fetchEmbeds = () => {
    axios.get(`/api/o/${match.params.organization_id}/embeds.json`)
    .then(function(response){
      console.log(response);
      if(response.data.success){
        setEmbeds(response.data.embeds);
      } else {

      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured")
    })
    .then(function () {
      // always executed
    });
  }

  const handleSubmit = (evt) => {
    evt.preventDefault();
    setDisabled(true);

    axios.put(`/api/o/${match.params.organization_id}`, {
      disable_brand_assets: !disableBrandAssets,
      disable_referrals: !disableReferrals,
      disable_services: !disableServices,
      disable_invoices: !disableInvoices
    })
    .then(function(response){
      console.log(response);
      if(response.data.success){
        notice("Update successful")
      } else {
        response.data.errors.forEach((error) => {
          notice(error);
        });
      }
    })
    .catch(function(error){
      console.log(error)
      notice("An error occured");
      reportError(`File: Status.js.requestUrl: ${error.config.url}. StackTrace: ${error.stack}.`);
    })
    .then(function () {
      setDisabled(false);
    });
  }

  const embeds_list = embeds.map((embed) => {
    const deleteEmbed = () => {
      axios.delete(`/api/o/${match.params.organization_id}/embeds/${embed.id}.json`)
      .then(function(response){
        console.log(response);
      })
    } 
    
    return (
      <div className="background-3 default-padding border-radius border-all dark-background-3 mb-15">
        <div className="row align-items-center">
          <div className="col-12 col-md-8">
            <h5 className="font-weight-600"><i class="fal fa-shapes mr-10"></i>{embed.title}</h5>
            <p>{embed.description}</p>
          </div>

          <div className="col-12 col-md-4 text-right">
            <a className="btn btn-danger-outline btn-small" onClick={deleteEmbed}>Delete</a>
          </div>
        </div>
      </div>
    )
  });

  return(
    <React.Fragment>
      <form onSubmit={handleSubmit}>

        <div className="mb-15">
          <div className="col-12 display-flex align-items-center justify-content-between">
            <div>
              <h4 className="margin-none">Customize your clients portal</h4>
              <p className="opacity-6 font-12">Enable or disable certain links from appearing on the client portals sidenavigation</p>
            </div>

            <div className="text-right">
              <a className="btn btn-primary btn-small" onClick={() => setOpen(true)}>
                <i className="fal fa-plus mr-10"></i>
                Create embed
              </a>

              <Modal open={open} onClose={() => setOpen(false)} className="modal-body-white" classNames={{modal: 'modal-full-width'}} center>
                <EmbedForm closeModal={() => setOpen(false)} refetchData={fetchEmbeds}/>
              </Modal>
            </div>
          </div>
        </div>

        {/* Brand assets */}
        <div className="background-3 default-padding border-radius border-all dark-background-3 mb-15">
          <div className="row align-items-center">
            <div className="col-12 col-md-8">
              <h5 className="font-weight-600"><i class="fal fa-shapes mr-10"></i>Brand Assets</h5>
              <p>Lets your clients upload files and links related to their brand assets. </p>
            </div>

            <div className="col-12 col-md-4 text-right">
              <Toggle checked={disableBrandAssets} onChange={() => setDisableBrandAssets(!disableBrandAssets)}/>
            </div>
          </div>
        </div>

        {/* Services */}
        <div className="background-3 default-padding border-radius border-all dark-background-3 mb-15">
          <div className="row align-items-center">
            <div className="col-12 col-md-8">
              <h5 className="font-weight-600"><i className="fal fa-cubes mr-10"></i>Services</h5>
              <p>Services shows your public catalog of services you offer and available for purchase.</p>
            </div>

            <div className="col-12 col-md-4 text-right">
              <Toggle checked={disableServices} onChange={() => setDisableServices(!disableServices)} />
            </div>
          </div>
        </div>

        {/* Invoices */}
        <div className="background-3 default-padding border-radius border-all dark-background-3 mb-15">
          <div className="row align-items-center">
            <div className="col-12 col-md-8">
              <h5 className="font-weight-600"><i className="fal fa-credit-card mr-10"></i>Invoices</h5>
              <p>Invoices lets your clients view all payments they've made to you.</p>
            </div>

            <div className="col-12 col-md-4 text-right">
              <Toggle checked={disableInvoices} onChange={() => setDisableInvoices(!disableInvoices)} />
            </div>
          </div>
        </div>

        {/* Referrals */}
        <div className="background-3 default-padding border-radius border-all dark-background-3 mb-15">
          <div className="row align-items-center">
            <div className="col-12 col-md-8">
              <h5 className="font-weight-600"><i className="fal fa-hand-holding-usd mr-10"></i>Referrals</h5>
              <p>Referrals lets your clients refer you to new clients. Disabling this will not disable the program if you've set it up.</p>
            </div>

            <div className="col-12 col-md-4 text-right">
              <Toggle checked={disableReferrals} onChange={() => setDisableReferrals(!disableReferrals)} />
            </div>
          </div>
        </div>

        {embeds_list}

        <div className="field text-right">
          <button type="submit" className="btn btn-primary">
            <i className="fal fa-save mr-10"></i>
            Save
          </button>
        </div>
      </form>
    </React.Fragment>
  )
}

export default ClientPortal;